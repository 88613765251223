@import "../../variables/variables.less";

.badge {
    color: @White;
    background-color: @GreenRebranding;
    border-radius: 4px;

    &.badge-primary {
        background-color: @GreenRebranding;;
    }
    &.badge-lg {
        font-size: 14px;
        padding: 8px 16px;
    }
}

// hotsale fix
.badge-spacer {
    margin-bottom: 72px;
    margin-top: -16px;
}

.badge-spacer-bottom {
    margin-bottom: 72px;
    margin-top: -32px;
}

.badge-spacer-mac {
    margin-bottom: 60px;
    margin-top: -42px;
}
.badge-spacer-bottom-mac {
    margin-bottom: 52px;
    margin-top: -62px;
}

.badge-spacer-table {
    z-index: 101;
    position: relative;
    top: -12px;
}
