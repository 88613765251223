/*
Platform-switcher
=================
*/

/* # Show default variant for users without javascript */
html.no-js {
	.js-platform-switch {
		.js-pc {
			position: relative;
			left: auto;
		}
		.js-android,
		.js-android-market,
		.js-ios,
		.js-mac {
			position: absolute;
			left: -9999px;
		}
	}
}

/* # PC */
html.js-root-pc {
    .js-platform-others {
        .js-pc {display: none;}
    }
    .js-platform-switch {
        .js-android,
        .js-android-market,
        .js-ios,
        .js-mac{
            display: none;
        }
    }
}

/* # Android */
html.js-root-android {
    .js-platform-others {
        .js-android {display: none;}
    }
    .js-platform-switch {
        .js-pc,
        .js-ios,
        .js-mac {
            display: none;
        }
    }
}
/* # iOS */
html.js-root-ios {
    .js-platform-others {
        .js-ios {display: none;}
    }
    .js-platform-switch {
        .js-android,
        .js-android-market,
        .js-pc,
        .js-mac {
            display: none;
        }
    }
}
/* # Mac */
html.js-root-mac {
    .js-platform-others {
        .js-mac {display: none;}
    }
    .js-platform-switch {
        .js-android,
        .js-android-market,
        .js-pc,
        .js-ios {
            display: none;
        }
    }
}


// Headlines
.headline-all,
.headline-pc,
.headline-mac,
.headline-mobile {
    display: none;
}

.headline-all.active,
.headline-pc.active,
.headline-mac.active,
.headline-mobile.active {
    display: block;
}

// Sections
#section-all,
#section-pc,
#section-mac,
#section-mobile {
    display: none;
}

#section-all.active,
#section-pc.active,
#section-mac.active,
#section-mobile.active {
    display: block;
}
