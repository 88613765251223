@import '../../variables/variables.less';
@import './form-select.less';

@media @desktop, @tablet {
	.row {
		&.actionbox {
			display: flex;
			flex-direction: row;
			align-items: stretch;
			// margin-bottom: 20px;
			width: 80%;
			margin: 0 auto;
		}
	}
}

.vue-action-box {
	.actionbox-container {
		position: relative;
		background: @White;
		border-radius: 3px;
		padding: 36px 20px 36px;
		width: 100%;
		min-height: 300px;
		box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
		margin-top: 23px;
		display: flex;
		justify-content: center;
		align-items: center;

		.toggler-option {
			&.disabled {
				border: solid 1px rgba(199, 205, 215, 0.6);
				background-color: @Grey-6;
				color: @Grey-1;
				border-radius: 0;
				line-height: 38px;
			}
		}

		.form-select-arrow {
			.form-select {
				border-radius: 0;
				background-color: @White;
				border: solid 1px @Grey-4;
				padding-bottom: 11px;
			}

			&:not(.disabled) {
				&:after {
					content: url(../i/ico/icon-arrow-down-blue.svg);
				}
			}
		}
	}

	// Toggler
	.form-select-container {
		display: inline-block;
		top: -19px;
		position: absolute;
		right: 0;
		left: 0;
		margin: 0 auto;
		text-align: center;
		min-height: 70px;
	}
	// /Toggler

	.box {
		text-align: center;
		border-right: 1px solid @Grey-4;
		flex: 1;

		a {
			color: @Blue;
			padding-bottom: 2px;
			//border-bottom: 1px dotted @Blue;

			&:hover,
			&:focus,
			&:active {
				text-decoration: none;
			}

			&.actionbox-button,
			&.try-button {
				min-width: 139px;
				padding: 11px 36px;
				border-radius: 4px;
				background-image: linear-gradient(to bottom, #57c131, #399f2d);
				display: inline-block;
				color: @White;
				text-decoration: none;
				margin: 20px 0 10px 0;
				text-align: center;
				//font-family: AVGSans_4, Verdana;

				&:hover,
				&:focus,
				&:active {
				  background-image: none;
				  background-color: #399e2d;
				  text-decoration: none;
				}
			}
		}

		/* p,
		.subscription-text,
		.subscription-trigger,
		.renewal-trigger {
			color: @Grey-1;
			font-size: 14px;
			padding: 0;
			margin: 0;
		} */

		//installment comes from pricing portal with <b> tag
		.installment {
			font-weight: 400;
		}

		&:last-of-type {
			border-right: none;
		}

		@media @mobile {
			width: 100%;
			border-right: none;
			display: none;

			&:first-of-type {
				display: block;
			}
		}
	}

	.actionbox-buy {
		height: 100%;
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: stretch;

		@media @mobile {
			padding-top: 10px;
			display: block;
			width: 100%;
		}
	}

	.actionbox-title {
		color: @Grey-0;
		font-size: 24px;
		line-height: 36px;
		font-weight: bold;
		display: block;
		margin-bottom: 16px;

		@media @mobile {
			font-size: 16px;
			line-height: 24px;
			margin-bottom: 8px;
		}
	}

	.promotion {
		.actionbox-title {
			color: @Blue;
		}
	}

	.hide-desktop-title {
		@media @desktop {
			display: none;
		}
	}

	.hide-mobile-title {
		@media @mobile {
			display: none;
		}
	}

	.hide-desktop-period {
		@media @desktop {
			display: none;
		}
	}

	.platforms {
		align-items: center;
		display: flex;
		justify-content: space-around;
		margin: 0 auto;
		margin-bottom: 24px;
		max-width: 125px;
		min-height: 30px;
		gap: 8px;

		@media @desktop {
			display: none;
		}

		img {
			width: 16px;
			height: 16px;
		}
	}

	.actionbox-price-main {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		min-height: 50px;
	}

	.period-wrap .price-wrapper {
		color: @Black;
		display: inline-flex;
		align-items: stretch;
		position: relative;
		//font-weight: 300;
		margin-bottom: -25px;
		justify-content: center;

		&.price-row-medium {
			font-size: 34px;
		}

		.integer {
			font-size: 34px;
			line-height: 34px;
			letter-spacing: -0.044em;
		}

		.decimal {
			font-size: 14px;
			height: 17px;
			line-height: 17px;
			color: @Grey-2;
		}

		.price-prefix {
			display: flex;
			margin-right: 2px;
			align-items: flex-end;

			.currency {
				font-size: 14px;
				line-height: 17px;
			}
		}

		.price-suffix {
			text-align: left;
			display: flex;
			flex-direction: column;
		}

		.price-suffix-bottom {
			font-size: 14px;
			line-height: 17px;
			margin-top: auto;
		}
	}

	.label {
		font-size: 14px !important;
		line-height: 20px;
		background-color:#d4e5f9;
		border-radius: 12px;
		margin: 0 auto 16px;
		padding: 2px 16px;
		
		display: flex;
		justify-content: center;
		align-items: center;
		width: fit-content;
		text-align: center;
		height: fit-content;
		
		// label for mobile is available only in table grid layout
		&.label-table-mobile {
			display: none;
		}
	}

	.label-text {
		font-size: 14px;
		line-height: 20px;
		text-transform: uppercase;
		line-height: normal;
		color: @Blue;
		font-weight: bold;
	}

	.label-placeholder {
		height: 24px;
		margin-bottom: 16px;
	}

	.rendered-price {
		display: inline;
	}

	.actionbox-price-main {
		display: flex;
		flex-direction: column;
	}

	.period-wrap {
		display: inline;
		margin-bottom: 5px;
	}

	.old-price {
		font-size: 14px;
		color: @Grey-2;
		text-decoration: line-through;
		display: inline-block;
	}

	.discount-placeholder {
		height: 24px;
	}


	.month-price {
		display: inline-block;
		font-size: 12px;
		line-height: 18px;
		color: #5C707B;

		.price-wrapper {
			font-size: 12px;
			line-height: 18px;
			min-height: unset;
		}
	}

	.header-footer-wrap {
		display: flex;
		flex-direction: column;
		height: 100%;
		position: relative; // Bottom text is absolutely positioned. Have to be distributed in each box separately.
	}

	.header-wrap {
		display: flex;
		flex-direction: column;
		//flex: 1;
		padding: 0 4px;
	}
	.labelActive {
		//flex: 2;
		//min-height: 56px;
	}
	.footer-wrap {
		display: flex;
		flex-direction: column;
		//flex: 2;
	}

	.button {
		margin-bottom: 10px;
		margin-top: 20px;

		span {
			min-width: 139px;
		}
	}

	.bottom-text,
	.cta-note {
		//left: 0;
		//right: 0;
		font-size: 14px;
		line-height: 21px;
		//font-weight: 500;
		
		@media @desktop {
			min-height: 50px;
			padding: 4px 33px;
		}

		@media @mobile {
			font-size: 11px;
			line-height: 16px;
		}

		/* p {
			margin-bottom: 5px;
		} */
	}

	.bottom-text,
	.cta-note {
		.link-text,
		.bi-download-link,
		.bi-cart-link {
			font-size: 14px;
			line-height: 21px;
			display: inline-block;
			padding: 12px 0 0;
			text-decoration: 1px dashed @Blue underline;

			@media @desktop {
				font-size: 16px;
				line-height: 24px;
				padding: 16px 0 0;
			}
			
			.link-text {
				display: inline;
				padding: 0;
			}
		}
	}

	.store-link {
		display: block;
		margin-top: 25px;
	}
}

.actionbox-btm {
	.vue-action-box {
		box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
	}
}

.horizontal-toggler {
	display: flex;
	justify-content: center;
	top: -19px;
	position: absolute;
	right: 0;
	left: 0;
	margin: 0 auto;

	@media @mobile {
		margin: 0 20px;
	}

	.horizontal-toggler-container {
		display: flex;


		@media @mobile {
			max-width: 100%;
		}

		.toggler-option {
			border: 1px solid #BBC1CC;
			border-radius: 3px;
 			background-color: #D6DAE0;
			color: #000;
			cursor: pointer;
			line-height: 40px;
			min-width: 80px;
			padding-left: 20px;
			padding-right: 20px;
			font-size: 14px;
			text-align: center;
			white-space: nowrap;

			&.disabled {
				cursor: initial;
			}

			&:last-child:not(:only-child) {
				border-right: none;
			}

			&.active {
				background-color: @White;
				color: #ff7800;
				border: 1px solid #ff7800;
				font-weight: bold;
				letter-spacing: -0.3px;
			}
		}
	}
}

// Promotion - higlight promoted plan
.vue-action-box .box.promotion {
	a.actionbox-button {
		background-image: linear-gradient(to bottom, #fe691f 0%, #ff471c 100%);
		background-repeat: repeat-x;
		color: #ffffff;
	}
	a.actionbox-button:hover,
	a.actionbox-button:focus,
	a.actionbox-button:active{
		background-color: #ff471c;
	}
	a.actionbox-button:hover,
	a.actionbox-button:focus,
	a.actionbox-button:active {
		background-color: #ff471c;
	}
	a.actionbox-button:hover,
	a.actionbox-button:focus,
	a.actionbox-button:active {
		background-color: #ff471c;
	}
}

// Store actionbox buttons
.store {
	.vue-action-box {
		.box {
			a {
				&.actionbox-button,
				&.try-button {
					font-size: 18px;
					line-height: 24px;
					font-weight: bold;
					min-width: 139px;
					width: fit-content;
					background-color: @Blue;
					background-image: none;
					display: inline-block;
					color: @White;
					text-decoration: none;
					margin: 16px auto 8px;
					//font-family: AVGSans_4, Verdana;
					padding: 0;

					span {
						display: inline-flex;
						justify-content: space-between;
						align-items: center;
						vertical-align: middle;
						//padding: 6px 32px;
						border-radius: 6px;
						height: 56px;
					}

					&:hover,
					&:focus,
					&:active {
					background-image: none;
					background-color: #4796f5;;
					}
				}
			}
		}
	}
}

// Ultimate box specific CSS
.actionbox-ultimate {
	.box {
		.actionbox-title {
			margin-bottom: 32px;
		}
	}
}

.footer-wrap {
	.cta-note .renewal-trigger,
	.subscription-trigger {
		cursor: pointer;

		.icon-info {
			display: inline-block;
			width: 18px;
			height: 18px;
			transition: all 200ms linear;
			position: relative;
			top: 3px;
			left: 3px;
			background: url('https://static2.avg.com/4/web/i/ico/ico-info-16x16-white.svg') no-repeat;
			background-size: 18px 18px;
		}
		&:hover {
			.icon-info {
				background: url('https://static2.avg.com/4/web/i/ico/ico-info-16x16-dark.svg') no-repeat;
				background-size: 18px 18px;
			}
		}
	}
	.nowrap {
		white-space: nowrap;
	}

	.yearly-price,
	.yearly-price-text,
	.bottom-text {
		color: @Grey-1;
		font-size: 12px;
		line-height: 18px;
	}

	.yearly-price {
		margin-bottom: 8px;

		s {
			color: @Grey-2;
		}
	}

	.yearly-price-text {
		margin-bottom: 4px;
	}

	.row-short,
	.row-long {
		font-size: 14px;
		line-height: 17px;
	}
}

// Mobile table actionbox
.table .vue-action-box {
	@media @desktop {
		.actionbox-title {
			display: none;
		}
	}

	@media @mobile, @tablet {
		width: 100%;
		
		.actionbox-container {
			display: grid;
			background-color: transparent;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: auto;
			column-gap: 16px;
			align-items: start;
			align-content: start;
			padding: 0;
			grid-template-areas: 
				"title priceColumn"
				"platforms priceColumn"
				"toggler priceColumn"
				". priceColumn"
				". priceColumn";

			// override the DOM structure
			> .row.actionbox-buy {
				display: contents;

				> .box {
					display: contents;

					> .header-footer-wrap {
						display: contents;

						>.header-wrap {
							display: contents;
						}
					}
				}
			}

			// grid areas
			.toggler {
				grid-area: toggler;
				position: relative;

				.form-select-container {
					position: relative;
					top: 0;

				}
			}

			.actionbox-title {
				grid-area: title;
				text-align: start;
			}

			.platforms {
				grid-area: platforms;
				margin: 0 0 16px;
				justify-content: start;
			}

			.label:not(.label-table-mobile) {
				display: none;
			}

			.label-table-mobile {
				display: inline-block;
				margin-inline-end: 0;
			}

			.footer-wrap {
				display: flex;
				flex-direction: column;
				align-items: end;
				text-align: end;
				grid-area: priceColumn;

				.actionbox-price-main {
					align-items: end;
				}

				.actionbox-button {
					margin: 16px 0 12px;
				}

				.bottom-text,
				.cta-note {
					padding-left: 0;
					padding-right: 0;
					text-align: end;
					width: 200%;
				}
			}
		}

	}
}

// long prices, period texts alignment

// default
.row-long {
	display: none;
}

@media @mobile-small {
	.row-discount {
		.row-long {
			display: block;
		}
		.row-short {
			display: none;
		}
	}
}

// exceptions for all prices
[lang*='-id'],
[lang='es-co'],
[lang='ms-my'],
[lang='ja-jp'],
[lang='tr-tr'],
[lang='pl-pl'],
[lang='fr-ca'],
[lang='en-za'],
[lang='fr-ch'] {
	.row-discount {
		.row-long {
			display: block;
		}
		.row-short {
			display: none;
		}
	}
}

[lang='ms-my'],
[lang='zh-tw'],
[lang='pl-pl'],
[lang='cs-cz'],
[lang='no-no'],
[lang='en-dk'],
[lang='en-se'],
[lang='en-in'],
[lang='en-ca'],
[lang='en-gb'] {
	.boxCount-3 {
		@media only screen and (min-width: 768px) and (max-width: 1345px) {
			.row-discount {
				.row-long {
					display: block;
				}
				.row-short {
					display: none;
				}
			}
		}
	}
}