@charset "utf-8";

@import "variables/variables.less";
@import "style-guide/components/vue-universal-actionbox-store.less";
@import "style-guide/components/all-products-platform-switcher.less";
@import "comparison-table-store.less";
@import "mixins/abtest.less";
@import "style-guide/components/badge.less";


.secure-identity {

	.footer-wrap {
		.bottom-text {
			font-size: 14px;
			line-height: 21px;
			color: #4B5563;
		}
	}
}

// FIX MOBILE DESIGN - GLOWEB-2404
#comparison {
	.avg-comparison-table {
		@media @mobile {
			.sticky-header {
				.product {
					.like-h4 {
						height: 70px;
					}
				}
			}
		}

		@media @mobile, @tablet {
			&.is-sticky {
				.sticky-header {
					.product {
						width: 33.3333%;
						border-bottom: solid 1px #EBECF0;
					}
				}
			}

			.sticky-header {
				.product {
					height: 235px;

					a.btn-store,
					a.btn-store-outline {
						border-radius: 6px;

						@media @desktop {
							display: none;
						}
					}
					
					a.btn-store {
						background-color: #2276D9;
					}

					a.btn-store-outline {
						border: 1px solid #2276D9;
					}

					&:not(.bgGray) {
						background-color: #fff;
					}

					.like-h4 {
						font-size: 16px;
					}
				}
			}

			.pricing {
				&.mobile {
					> div:not([class]) {
						display: flex;
						flex-wrap: wrap;
						width: 50%;
					}

					.cta {
						flex-basis: 50%;
						text-align: right;

						img {
							height: auto;
						}

						.js-ios,
						.js-mac {
							img {
								width: 160px;
							}
						}

						.js-android,
						.js-pc {
							img {
								width: 200px;
							}
						}

						/* p::not([class]) {
							font-size: 24px;
							font-weight: 700;
							line-height: 36px;
							letter-spacing: 0px;
							text-align: left;
						} */
					}
					
					/* .price {
						color: inherit;
						font-size: 16px;
						&.highlited {
							color: #2A7DE1;
							font-weight: 700;
							line-height: 24px;
						}
					} */

					/* .price-old {
						margin: 4px 0 6px 0;
					} */

					a.button {
						+ p {
							// display: none;
							text-align: start;
						}
					}

					/* a.text-small {
						display: inline-block;
						margin-top: 10px;
						text-decoration: underline dotted;
					} */

					.like-h4 {
						display: inline-block;
					}

					img {
						width: 22px;
						height: 22px;
						//margin: 0 4px;
					}

					.label {
						//max-height: 25px;
					}
				}
			}

			.moneyBack {
				left: 50% !important;
  			transform: translateX(-50%);
			}
		}
	}
}
// end of fixes for MOBILE DESIGN GLOWEB-2404


html, body {
	&.js-ios {
		#privacy {
			.visibleForiOS {
				& + .content-pc,
				& + .content-mac,
				& + .content-android {
					display: none;
				}
			}
		}
	}
}

.store {
	@media @tablet {
		margin: 0 20px;
	}
}

.platforms {
	display: none;

	@media @mobile {
		margin-bottom: 44px;

		&.mobile {
			display: block;
		}
	}
	@media @desktop {
		&.desktop {
			display: block;
		}
	}
}

/* common styles */
#comparison {
	table {
		border-collapse: separate;
	}
}

#comparison table .product .header a:has(.btn-store) {
	display: none;
}

#top,
#comparison,
#privacy,
#performance,
#ultimate,
#service {
	margin-bottom: 80px;

	@media @desktop {
		text-align: center;
	}

	@media @mobile, @tablet {
		margin-bottom: 64px;
	}

	.heading {
		margin: 0 20px 64px;
		@media @mobile, @tablet {
			margin-bottom: 24px;
		}
	}

	.container {
		@media @mobile, @tablet {
			padding: 16px 20px 0;
		}

		&.two-col,
		&.one-col,
		&.full {
			@media @desktop {
				padding-left: 0;
				padding-right: 0;
			}

			h3 {
				font-size: 20px;
				@media @mobile, @tablet {
					font-size: 20px;
					margin: 0;
				}
			}

			.row {
				margin-left: 0;
				margin-right: 0;
			}

			.span3 {
				padding-left: 8px;
				padding-right: 8px;
			}

			.span6,
			.span8,
			.span4 {
				background-color: @Grey-6;
				border: solid 1px @Grey-5;
				display: flex;
				flex-direction: row;
				padding: 43px;

				&.no-brder {
					border: none;
				}

				@media @desktop, @tablet {
					&:first-child {
						margin-right: 32px;
					}
				}

				@media @mobile, @tablet {
					flex-direction: column;
					padding: 20px;
					margin-bottom: 16px;
					justify-content: center;
					align-items: center;
				}

				.col-actionbox {
					width: 317px;

					@media @mobile, @tablet {
						width: 293px;
					}
				}
			}
			.span8 {
				border: none;
			}

			.col-text {
				text-align: left;
				@media @desktop {
					margin-right: 65px;
					width: 194px;
				}

				@media @tablet {
					min-width: 194px;
				}

				.product-ico {
					margin-bottom: 24px;

					@media @desktop {
						width: 60px;
					}

					@media @mobile, @tablet {
						float: left;
						margin-right: 20px;
					}
				}
			}

			.col-actionbox {
				.free {
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					background-color: @White;
					position: relative;
					min-height: 300px;
					margin-top: 23px;
					box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
					padding-top: 0;
					&.trial {
						padding: 44px 20px 51px;
						@media @desktop {
							margin-left: 16px;
						}
					}

					.label {
						display: flex;
						flex-direction: row;
						position: absolute;
						top: -21px;

						div {
							border: solid 1px rgba(199, 205, 215, 0.6);
							background-color: @Grey-6;
							font-size: 14px;
							padding: 9px 20px;
							text-transform: capitalize;
							min-width: 80px;
							color: @Grey-1;
							font-weight: normal;
						}
					}

					img {
						max-width: 200px;
					}

					h3 {
						margin-bottom: 20px;
						&.actionbox-free-title {
							margin-bottom: 0;
						}
					}
					.actionbox-free-text {
						font-size: 14px;
						font-weight: normal;
						color: #4d5566;
						padding-bottom: 73px;
						padding-top: 16px;
					}
					.actionbox-free-footer {
						font-size: 14px;
						font-weight: normal;
						color: #4d5566;
						padding-top: 12px;
					}
					p {
						&.actionbox-free-footer {
							margin-bottom: 26px;
						}
					}
				}
			}
		}
		&.two-col {
			@media screen and (max-width: 1380px) and (min-width: 1056px) {
				.row {
					.span4, .span6, .span8 {
						flex-direction: column;
						padding: 20px;
						margin-bottom: 16px;
						justify-content: center;
						align-items: center;
						.col-text {
							width: auto;
							margin: 0 0 2rem;
							.product-ico {
								margin-bottom: 24px;
								float: left;
								margin-right: 20px;
							}
							h3 {
								font-size: 20px;
								margin: 0;
							}
						}
					}
				}
			}
		}

		&.one-col {
			.illustration {
				background-color: @White;
				border: none;
				padding: 0;
				max-height: 500px;

				@media @mobile, @tablet {
					display: none;
				}
			}
		}
	}

	.grey {
		border: solid 1px @Grey-5;
	}

	.text {
		text-align: left;
	}

	.section {
		color: @Green;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: bold;
	}

	.two-col {
		.span6 {
			@media @mobile {
				margin-bottom: 16px;
			}
		}
	}
	.one-col {
		.span6 {
			@media @desktop {
				margin-top: 32px;

				&.discount {
					max-height: 100%;
				}
			}

			@media @mobile {
				margin-top: 0;
				margin-bottom: 0;
			}
		}
	}

	.platform-icons {
		margin-bottom: 25px;

		img {
			width: 24px;
			height: 24px;
			margin-right: 12px;
			filter: invert(57%) sepia(2%) saturate(5360%) hue-rotate(192deg) brightness(87%) contrast(65%);

			@media @mobile {
				width: 16px;
				height: 16px;
			}
		}
	}

	.features-link {
		a {
			text-decoration: none;
			border-bottom: 1px dotted @Blue;
			padding-bottom: 2px;
		}

		.platforms;
	}

	.details {
		display: none;

		@media @mobile {
			display: flex;
			margin: 24px auto 0;
			color: @Grey-1;
			text-transform: uppercase;
			min-width: 89px;
			border-radius: 5px;
			box-shadow: 0 8px 23px -8px rgba(78, 34, 208, 0.16),
				0 8px 23px -8px rgba(12, 5, 62, 0.06);
			border: solid 0.9px @Grey-1;
			text-decoration: none;
			padding: 9px 18px;
			font-size: 9px;
			letter-spacing: 0.6px;
			justify-content: center;
			align-items: center;

			&:after {
				content: "";
				width: 12px;
				height: 12px;
				display: block;
				margin-left: 7px;
				background-image: url(../i/store/top-xs.svg);
			}

			&.close {
				&:after {
					transform: rotate(180deg);
				}
			}
		}
	}

	.button {
		&.inactive {
			opacity: 0.5;
			cursor: default;

			span {
				background-image: url(../i/buttons/win-green.svg);
				color: #00b451;
				background-color: #fff;
			}
		}
	}
}

#privacy {
	.button {
		&.inactive {
			span {
				background-image: none;
			}
		}
	}
}
/* /common styles */

#top {
	margin-top: 88px;
	margin-bottom: 64px;

	@media @desktop {
		h1 {
			font-size: 56px;
			line-height: 64px;
		}
	}

	@media @mobile, @tablet {
		margin: 40px 0;
	}

	.sub-h1 {
		margin-bottom: 48px;
	}

	.filter {
		position: relative;

		@media @mobile {
			&:after {
				background: linear-gradient(
					90deg,
					rgba(255, 255, 255, 0) 0,
					rgba(255, 255, 255, 0) 54%,
					rgba(255, 255, 255, 0.6) 70%,
					rgba(255, 255, 255, 1) 100%
				);
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				width: 70px;
				height: 100%;
			}
		}

		.filter-items {
			width: 100%;
			display: flex;
			overflow: auto;

			@media @desktop, @tablet {
				justify-content: center;

				.mobile-spacer {
					display: none;
				}
			}

			@media @mobile {
				.mobile-spacer {
					display: block;
					padding-right: 50px;
				}
			}
		}
		a {
			color: @Black;
			background-repeat: no-repeat;
			background-position: 14px center;
			margin: 0 15px;
			text-decoration: none;
			padding: 10px 22px 10px 40px;
			white-space: nowrap;

			@media @desktop {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 222px;
				margin: 0 0 0 4px;
				padding: 10px 22px;
				font-size: 20px;

				img {
					margin-right: .5rem;
				}
			}

			@media @mobile {
				padding: 10px 18px;
				margin: 0;

				&.all, &.pc, &.mac, &.mobile {
					img {
						display: none;
					}
				}
			}

			@media @desktop, @tablet {
				&.all {
					// background-image: url(../i/store/all-devices.svg);
				}

				&.pc {
					// background-image: url(../i/store/win.svg);
				}

				&.mac {
					// background-image: url(../i/store/apple.svg);
				}

				&.mobile {
					// background-image: url(../i/store/mobile.svg);
				}
			}
		}
	}

	.filter a.active {
		border-radius: 33px;
		background-color: #ffb600;
	}

	.container {
		margin: auto;
	}

	.button-wrapper {
		padding: 10px 0;
	}
}

#privacy {
	.container {
		&.heading {
			margin-bottom: 64px;
		}
		&.grey {
			padding: 38px 48px;
			margin-bottom: 32px;

			@media @mobile, @tablet {
				padding: 20px;
				margin: 20px 20px 0;
			}
		}
		&.grey-de {
			padding: 0;
			margin-bottom: 32px;

			@media @mobile, @tablet {
				padding: 0;
				margin: 20px 20px 0;
			}
		}

		.span9 {
			padding-left: 0;
			padding-right: 0;
		}

		.span4 {
			&.secure-img {
				padding: 0;
				border: none;
				background-color: white;
			}
		}

		&.full {
			.actionbox-title:nth-of-type(2) {
				color: @Blue;
			}

			@media @tablet {
				.row:first-child {
					flex-direction: column;
				}
			}
		}

		.col-text {
			margin-top: 40px;

			@media @desktop {
				display: flex;
				flex-direction: column;
				align-items: center;

				p {
					text-align: center;
				}
			}
		}
	}
	.actionbox-securebrowser {
		.content-pc,
		.content-mac,
		.content-android {
			a.green {
				border-bottom: 0;
			}
		}
	}
	.content-android {
		.button {
			border-bottom: 0;
		}
	}
}

#ultimate {
	.actionbox-container {
		padding-bottom: 20px;
	}
}

#service {
	margin-bottom: 128px;

	.one-col {
		.span6 {
			&:first-child {
				border: none;
				padding: 0;
			}
		}
	}

	.actionbox-container {
		margin-top: 0;
		padding: 20px;

		// hide Price per month on "AVG Express Install" (https://butr.avast.com/browse/GLOWEB-5014)
		// note: Price per month available only in EN-GB locale
		.month-price {
			display: none;
		}

		.bottom-text {
			color: @Grey-1;
			font-size: 14px;
		}
	}
}

.product-table-sticky {
	position: sticky;
	z-index: 100;
	top: 0;
	background-color: #fff;
	width: 100% !important;
	height: 233px !important;
	padding-bottom: 24px;
	display: none;

	.table {
		max-width: 1263px;
		margin: 0 auto;
	}

	th {
		width: 316px;
		border-right: 1px solid @Grey-5;

		&.product {
			text-align: left;
			padding: 48px 48px 32px;

			&:first-child {
				padding-left: 0;
				padding-right: 10px;
			}

			.header {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-bottom: 24px;

				img {
					margin-top: 5px;
					margin-right: 16px;
				}

				h4 {
					margin-bottom: 0;
				}
			}

			@media @mobile {
				.platform-icons,
				.features-link {
					display: none;
				}
			}
		}
	}
}

.nav-main-link {
	.button {
		text-align: center;
	}
}

// HOTFIX for line on actionbox in comparsion table - WEBSITE-937
/* .en-gb,
.en-au,
.de-de {
	.table {
		tr td:nth-child(3),
		tr th:nth-child(3) {
			.vue-action-box {
				.actionbox-price-main {
					min-height: 71px;
				}
				.header-wrap {
					min-height: 57px;
				}
			}
		}
		tr td:nth-child(2),
		tr th:nth-child(2) {
			.desktop-pricing {
				h3 {
					margin-bottom: 50px;
				}
			}
		}
	}
} */
// /HOTFIX for line on actionbox in comparsion table - WEBSITE-937

//FIX for product icon size - WEBSITE-2266

.header {
	img {
		height: 100%;
	}
}
//FIX for product icon size - WEBSITE-2266

//FIX for floating element - WEBSITE-2585
.hmct-floating-el {
	.box-content {
		.help {
			font-family: "Roboto";
			font-weight: 200;
		}
	}
} //FIX for floating element - WEBSITE-2585

#comparison table .sticky-header th:nth-child(3) select:nth-child(2) {
	display: none !important;
	visibility: hidden !important;
}

// FIX: Ultimate is not supported on the store page - GLOWEB-2206
// Default: Table comparison will compare 3 products.
// This should fix table cell width in tables that have only 2 products to compare.
// TODO: Add this table variant in the design system
@media @mobile, @tablet {
	#comparison {
		.table {
			&.table--2-cols {
				thead th.product,
				tr td.tick  {
						width: 50%;
					}
				thead th.feature,
				tr td.feature {
					width: 100%;
				}
			}
		}

		// FIX: ru-ru - fix Sticky header cell width
		.avg-comparison-table.is-sticky {
			.table.table--2-cols {
				.sticky-header .product {
					width: 50%;
				}
			}
		}
	}
}


// FIX: "Try it free" link margin
.avg-comparison-table {
	.vue-action-box {
		.bottom-text p + .js-platform-switch {
			margin-top: 24px;
		}
	}
}


// FIX: decimal number followed by currency
.vue-action-box {
	.price-wrapper {
		.decimal {
			span:not(.currency) {
				margin-left: 5px;
			}
		}
	}
}


// FIX: spanish locales
.lng-es,
.lng-pt {
	.store {
		// buttons overlaping containers
		.actionbox-container.boxCount-3 {
			a.actionbox-button span {
				padding-left: 16px;
				padding-right: 16px;
			}
		}
	}

	// buttons not aligned because of various text length
	#privacy .container .span9 .actionbox .bottom-text {
		min-height: 85px;
	}
}


// GLOWEB-3429
.avg-comparison-table {
	.pricing {
		.like-h3 {
			margin-bottom: 60px;

			@media @desktop {
				margin-bottom: 40px;
			}

			@media @desktop-large {
				margin-bottom: 35px;
			}

			@media only screen and (min-width: 1056px) and (max-width: 1200px) {
				margin-bottom: 55px;
			}
		}
	}

    .button {
        &.primary {
            &.green {

                &.outline {
                    span {
                        color: #2a7de1;
                        background-color: #FFF;
                        border: 1px solid #2a7de1;
                    }

                    &.icon-win {
                        span {
                            background-image: url('../i/buttons/win-blue.svg');
                        }
                    }

                    &.icon-mac {
                        span {
                            background-image: url('../i/buttons/mac-blue.svg');
                        }
                    }
                }

                &:hover {
                    span {
                        background-color: @blueButtonBgColorHover;
                        color: @White;
                        box-shadow: none;
                    }

                    &.outline {
                        &.icon-win {
                            span {
                                background-image: url('../i/buttons/win-white.svg');
                            }
                        }

                        &.icon-mac {
                            span {
                                background-image: url('../i/buttons/mac-white.svg');
                            }
                        }
                    }
                }
            }
        }
    }
}
// /GLOWEB-3429

.text-blue {
	color: @Blue-v2;
}

// GLOWEB-7397
.avg-comparison-table .vue-action-box .footer-wrap {
	.en-gb & {
		//min-height: 300px !important;
	}
}

.avg-comparison-table .desktop-pricing {
	.en-gb & {
    //margin-top: 11px;
	}
}
// /GLOWEB-7397


// GLOWEB-7641
.trustpilot-widget {
	@media @mobile {
		height: 3rem;
	}

	@media @desktop {
		width: 50%;
	}
}

.button.primary.green.outline{
	&.icon-win>span {
		background-image: url('../i/buttons/win-blue.svg');
	}
	&.icon-mac>span {
		background-image: url('../i/buttons/mac-blue.svg');
	}

	&:hover {

		&>span {
			background-color: #2a7de1;
		}

		&.icon-win>span {
			background-image: url('../i/buttons/win-white.svg');
		}
		&.icon-mac>span {
			background-image: url('../i/buttons/mac-white.svg');
		}
	}

}

.button.primary.green.outline span {
	color: #2a7de1;
    background-color: #FFF;
    border: 1px solid #2a7de1;
}

#top {
	.filter {
		display: flex;
		justify-content: center;

		a.active {
			border-radius: 3rem;
			background-color: #fff;
		}

		.filter-items {
			width: auto;
			border-radius: 3rem;
			background-color: #dee8fa;
			padding: 4px 0;

			& a.all {
				margin-left: 4px;
			}

			& a.mobile {
				margin-right: 4px;
			}
		}
	}
}


#comparison {
	.table {
		thead .pricing {
			.mobile:last-child {
				justify-content: center;
			}

			&.desktop:not(.bgGray):not(.mobile) {
				padding-top: 4rem;
				padding-bottom: 3rem;
			}

			/* &.mobile {
				.label {
					font-size: 14px !important;
					background-color: #d4e5f9;
					border-radius: 11px;
					display: inline-block;
					margin-bottom: 16px;
					margin-top: 0;
					padding: 2px 9px;
					text-align: center;
					text-overflow: ellipsis;
					vertical-align: middle;
					white-space: nowrap;
					color: #2a7de1;
					width: auto;
				}

			} */

		}

		tbody {
			.pricing.mobile {
				display: block;
			}

			.pricing.desktop {
				padding: 0;
				border-top: none;
				vertical-align: top;

				@media @mobile {
					display: none;
				}

				&:not(.bgGray) {
					padding: 0;
				}

				.desktop-pricing {
					//margin-top: 0;
				}

				.vue-action-box .footer-wrap {
					.en-gb & {
						//min-height: 254px !important;
					}
				}

				.vue-action-box .actionbox-container {
					padding: 0 20px 20px;
				}

				.vue-action-box .note_above_cta {
					font-size: 13px;
					line-height: 18px;
					color: #5C707B;
				}
			}
		}
	}
}

#comparison table tr td.feature, #comparison table tr th.feature {
	@media @mobile {
		height: auto;
	}
}

#comparison .container .avg-comparison-table.is-sticky .sticky-header th.product {
	padding-bottom: 0;
}

#comparison .container .avg-comparison-table.is-sticky .sticky-header th.product {
	@media @mobile {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
}

#comparison table .pricing .like-h3 {
	@media @desktop {
		font-size: 32px;
	}
}

#comparison table tr td.feature span, #comparison table tr th.feature span {
	@media @mobile {
		text-align: center;
	}
}

#comparison table .product {
	padding: 48px 48px 0;
}

#comparison .avg-comparison-table .pricing.mobile a:not(.button) {
	max-height: 4rem;
}

#comparison .avg-comparison-table .pricing.mobile>div:not([class]) {
	flex-direction: column;
	justify-content: flex-start;
}

.platforms.mobile {
	margin-bottom: 25px;
}

.pricing.mobile {
	.form-select-arrow:after {
		//display: none;
	}

	.price {
		span {
			&.integer, &.decimal {
				font-family: Roboto;
				font-size: 24px;
				font-weight: 700;
				line-height: 36px;
				letter-spacing: 0px;
				text-align: left;
			}

			&.currency, &.period {
				font-size: 12px;
				line-height: 18px;
				letter-spacing: 0px;
				text-align: left;
			}

			&.period {
				font-weight: 700;
			}
		}
	}
}

#performance {
	.span6 {
		& > .col-text.text {
			display: flex;
			flex-direction: column;
			align-items: center;

			& > p {
				text-align: center;
			}
		}
	}
}

#ultimate {
	.span6 {
		& > .col-text.text {
			display: flex;
			flex-direction: column;
			align-items: center;

			& > p {
				text-align: center;
			}
		}
	}
}
// /GLOWEB-7641


// GLOWEB-9389
html[lang="en-us"] & {
	.secure-identity {

		.vue-action-box .actionbox-buy {
			@media @tablet {
				display: flex;
				flex-direction: row !important;
			}
			@media @mobile {
				display: flex;
				flex-direction: column;
				gap: 32px;
			}
		}
		.vue-action-box {
			.box {
				@media @mobile {
					display: block;
					border-right: none;
					border-bottom: 1px solid #D6DAE0;
					padding-bottom: 32px;
				}
				&:first-of-type {
					a.actionbox-button {
						@media only screen and (max-width: 1300px) {
							margin-top: 20px;
						}
					}
					.labelActive:first-of-type {
						margin-bottom: 76px;

						@media only screen and (max-width: 1300px) {
							margin-bottom: 72px;
						}
						@media @tablet {
							margin-bottom: 72px;
						}
					}
	
				}
				&:last-of-type {
					@media @mobile, @tablet {
						border-bottom: none;
					}
				}
			}
		}
	}
}

.secure-identity {
	.actionbox {
		.vue-action-box {
			.actionbox-container {
				.actionbox-buy {
					@media @tablet {
						flex-direction: row !important;
					}
				}
			}

			.box {
				@media @mobile {
					display: block;
					padding-bottom: 24px;
					border-bottom: 1px solid #D6DAE0;
					border-right: none;
				}

				&:last-of-type {
					@media @mobile {
						padding-top: 24px;
						padding-bottom: 0;
						border-bottom: none;
					}
				}
			}
		}
	}
}
// /GLOWEB-9389