@import 'variables/variables.less';

#comparison {
	.container {

		@media @desktop {
			padding: 38px 48px 0;
		}

		.avg-comparison-table {
			max-width: 1263px;
			margin: 0 auto;
			position: relative;

			/* Table sticky head */
			@media @desktop {
				&.is-sticky {
					.sticky-header {
						th.product {
							padding-bottom: 70px;
						}

						th:nth-child(n+3) {
							.upc-toggler-wrapper {
								position: absolute;
								top: 170px;
							}
						}

						th:nth-child(3) {
							.upc-toggler-wrapper {
								left: 75px;
							}
						}

						th:nth-child(4) {
							.upc-toggler-wrapper {
								left: 90px;
							}
						}

						.platform-icons {
							display: none;
						}

						.header img {
							display: none;
						}
					}
				}
			}

			@media screen and (max-width: 1210px) {
				&.is-sticky {
					.sticky-header {

						th:nth-child(n+3) {
							.upc-toggler-wrapper {
								position: absolute;
								top: 220px;
							}
						}

						th:nth-child(3) {
							.upc-toggler-wrapper {
								left: auto;
							}
						}

						th:nth-child(4) {
							.upc-toggler-wrapper {
								left: 70px;
							}
						}
					}
				}
			}

			@media @mobile, @tablet {
				&.is-sticky {
					.sticky-header {
						.feature {
							display: none;
						}
					}
				}
			}
			//* Table sticky head */


			.moneyBack {
				background: url(../i/store/money-back.svg);
				background-position: top left;
				background-repeat: no-repeat;
				padding-left: 40px;
				min-height: 32px;
				display: inline-flex;
				align-items: center;
				position: relative;
				left: 12%;
			}
		}

		.actionbox-container {
			box-shadow: none;
			margin-top: 0;
			min-height: auto;
		}
	}

	table {
		width: 100%;
		background-color: @White;
		margin-bottom: 40px;

		thead {

			tr {
				td {
					padding: 0;
					border: 0;
				}
			}

			@media @mobile, @tablet {
				background-color: @Grey-6;

				tr {
					&:first-child {
							display: none;
					}
				}

				th {
					&.product {
						width: 33%;
						flex-direction: row;
						padding: 20px;

						.header {
							flex-direction: column;
							text-align: center;

							img {
								margin: 10px auto;
							}
						}
					}
				}
			}
		}

		tfoot {
			tr {
				z-index: 200;
				position: relative;
				background-color: @White;
				transform: translate3d(0, 0, 0);
			}

			tr, td {
				vertical-align: top;
			}

			.footer-info {
				padding-top: 24px;
			}
		}

		tr {
			td,
			th {
				width: 316px;
				@media @mobile, @tablet {
					width: 100%;
				}

				&.product {
					@media @mobile, @tablet {
						background-color: @Grey-6;

						&:nth-child(n+2) {
							height: 180px;
						}

						h4 {
							font-size: 14px;
						}
					}
				}

				&.feature {
					text-align: left;


					@media @mobile, @tablet {
						width: 100%;
						font-size: 14px;
						// position: relative;

						h3 {
							font-size: 20px;
							margin-bottom: 0;
						}

						span {
							display: block;
							//position: absolute;
							bottom: 5px;
						}
					}
				}

				@media @desktop {

					&.highlight {
						border-left: solid 1px @Blue-v2;
						border-right: solid 1px @Blue-v2;

						&.tick {
							// This is @Blue-v2 with opacity....
							background-color: rgba(34, 118, 217, 0.1);
						}

						&.top {
							border-top: solid 1px @Blue-v2;
						}
					}
				}

				&.bgGray {
					background-color: @Grey-6;
				}

			}

			td {
				padding: 15px 0;
				border-top: solid 1px @Grey-5;

				@media @mobile, @tablet {
					padding: 9px 0;
					display: block;
					width: 33%;
					height: 48px;
				}
			}

			&.border {
				td {
					border-top: solid 1px @Grey-5;
					@media @mobile, @tablet {
						&.feature {
							border-bottom: none;
							height: auto;
						}
					}
				}
			}
			&.desktop {
				@media @mobile, @tablet {
					display: none;
				}

				td {
					border-top: none;
					padding: 0;
					border-bottom: solid 1px @Grey-6;

					&.highlight.bottom {
						border-bottom: solid 1px @Blue-v2;
					}
				}
			}
		}

		@media @mobile, @tablet {

			td, th {
				float: left !important;
				text-align: center;
			}
		}

		.product {
			text-align: left;
			padding: 48px 48px 32px;

			&:first-child {
				padding-left: 0;
				padding-right: 10px;
			}

			.header {
				flex-direction: row;
				align-items: center;

				@media @desktop {
					margin-bottom: 24px;

					.btn-store,
					.btn-store-outline {
						display: none;
					}
				}

				img {
					margin-top: 5px;
					margin-right: 16px;
				}

				h4 {
					margin-bottom: 0;

					@media @desktop {
						&.ultimate {
							margin-right: 40px;
						}
					}
				}

				a {
					display: flex;
					text-decoration: none;

					@media @mobile, @tablet {
						flex-direction: column;
					}
				}
			}

			@media @mobile, @tablet {
				.platform-icons,
				.features-link {
					display: none;
				}
			}

		}

		th.product {
		/* Table sticky head */
			background: @White;
			z-index: 100;
			border-bottom: none;
			position: sticky;
			top: 0;
			vertical-align: top;

			@media @desktop {
				//margin-bottom: 370px;
				//border-bottom: 1px solid @Grey-5;
			}
			@media screen and (min-width: 1056px) and (max-width: 1265px) {
				//margin-bottom: 400px;
			}
		}

		.sticky-header {
			position: sticky;
			top: 0;
			z-index: 100;

			.form-select {
				border-radius: 0;
				background-color: @White;
				border: solid 1px @Grey-4;
				padding-bottom: 11px;
			}

			.form-select[disabled="disabled"] {
				background-color: @Grey-6;
				opacity: 1;    //overwriting the standard 0.7 for disabled
			}

			th:nth-child(3) {

				.form-select-arrow {
					&:after {
						content: url(../i/ico/icon-arrow-down-blue.svg);
					}
				}
				.upc-toggler-wrapper {
					position: absolute;
					top: -15px;
					left: 80px;

					@media screen and (max-width: 1210px) {
						left: auto;
					}
				}
			}

			th:nth-child(4) {

				.upc-toggler-wrapper {
					position: absolute;
					top: -40px;
					left: 90px;
				}
			}

			@media @mobile, @tablet {
				.upc-toggler-wrapper {
					display: none;
				}
			}
		}
		//* Table sticky head */

		.pricing {
			display: none;
			padding-top: 0;
			padding-bottom: 0;

			// hide or show relevant buttons
			&.free-product {
				#section-mac.active & {
					.button.js-mac {
						display: inline-block;
					}
					.button:not(.js-mac) {
						display: none;
					}
				}

				#section-pc.active & {
					.button.js-pc {
						display: inline-block;
					}
					.button:not(.js-pc) {
						display: none;
					}
				}
			}



			h3, .like-h3 {
				font-size: 24px;
			}

			.free-header {
				font-size: 24px;
				font-weight: 700;
				line-height: 36px;
				color: @Grey-0;
				text-transform: uppercase;
				
				@media @desktop {
					margin-bottom: 57px;
				}
			}

			.product-title {
				font-size: 16px;
				line-height: 24px;
				font-weight: 700;
				color: @Grey-0;
				
				@media @tablet {
					font-size: 24px;
					line-height: 36px;
				}
			}


			@media @desktop {
				&.desktop {
					display: table-cell;

					&:not(.footer-info) {
						padding-top: 56px;
					}

					&.free-product .desktop-pricing {
						margin-top: 72px;

						// EN-GB will be different cause of more texts
						html[lang="en-gb"] & {
							margin-top: 68px;
						}
						// PT-BR will be different cause of installments
						html[lang="pt-br"] & {
							margin-top: 96px;
						}
					}
				}
			}

			@media @mobile, @tablet {

				&.mobile {
					display: block;
					padding: 24px 0;
					border-bottom: 1px solid @Grey-5;
					border-top: none;
					text-align: left;
					width: 100%;
					height: 100%;
					display: flex;
					flex-direction: row;
					background-color: @Grey-6;

					.play {
						width: 200px;
					}

					.platforms-free {
						display: flex;
						gap: 8px;
						margin-top: 8px;
					}

					div {
						&.free-txt {
							width: 50%;
						}

						p {
							margin: 18px 0 0;
							font-size: 14px;
						}
						&.cta {
							text-align: center;
							margin: 0 auto;

							.button {
								margin-top: 10px;
							}
						}

						a {
							&:hover,
							&:active,
							&:visited {
								text-decoration: none;
							}
						}

					}

					/* .label {
						font-size: 14px !important;
						background-color:#d4e5f9;
						border-radius: 11px;
						display: inline-block;
						margin-bottom: 16px;
						margin-top: 0;
						padding: 2px 9px;
						text-align: center;
						text-overflow: ellipsis;
						vertical-align: middle;
						white-space: nowrap;
						color: @Blue;
						width: auto;
					} */

					.price {
						color: @Blue;
						font-size: 20px;
						margin-top: 0;
					}

					.price-old {
						text-decoration: line-through;
					}


				}
			}
		}
	}

}

/* Localisation fixes */

/* .en-us {
	#comparison {
		.pricing {
			h3 {
				margin-bottom: 41px;
			}
		}

		th.product {
			//border-bottom: none;

			@media @desktop {
				margin-bottom: 360px;
			}
		}
	}
} */

/* WEBSITE-3042 discount fixes */
.desktop-pricing {
	//margin-top: 30px;
}

/* browser secific */
.br-safari {
	#comparison {
		.sticky-header {
			z-index: auto;
		}
	}
}
